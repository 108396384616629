import React from 'react';
import styled from 'styled-components';

const NotFoundStyles = styled.div`
  h1,
  h3,
  p {
    text-align: center;
  }

  .header {
    background-image: url('/noise.svg') !important;
    padding: 10rem;
  }
`;

export default function FourOhFourPage() {
  return (
    <NotFoundStyles>
      <div className="header">
        <h1>404</h1>
        <h3>Page not found</h3>
        <p>It's not me. It's you.</p>
      </div>
    </NotFoundStyles>
  );
}
